import {callApi} from '@/api/api-core'

export const SendNotification = function(title, message, utcDateFrom, utcDateTo, type, recycleApiKeys, identifier){
    const requestData = {
        Title: title,
        Message: message,
        UtcDateFrom: utcDateFrom,
        UtcDateTo: utcDateTo,
        Type: type,
        RecycleApiKeys: recycleApiKeys,
        Identifier: identifier
    }
    return callApi('POST', 'api/notification/send', requestData)
}