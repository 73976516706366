import {createStore} from 'vuex'

import Notifications from './store/Notifications'
import User from './store/User'
import Softerrors from './store/softerrors'

const modules = {
    notification: Notifications,
    user: User,
    softerrors: Softerrors
}

const store = createStore({
    strict: true,
    modules,
    actions: {
    },
})

export default store
