import {login} from '@/api/User/Login'
import {myUser as apiMyUser} from '@/api/User/MyUser'

export default{
    actions: {
        login: (context, payload) => {
            return login(payload.username, payload.password).then(response => {
                if (response.status === 200 && response.data.access_token) {
                    // If the return is positive and we have an access token. we save it in local storage
                    localStorage.setItem('token', response.data.access_token)
                    localStorage.setItem('refreshtoken', response.data.refresh_token)
                    // Get my profile
                    // context.dispatch('fetchMyUser')
                } else if (response.status === 400) {
                    // We could not log in
                    // We check if two factor authentication required, if it does, we handle the response in Login.vue
                    if (response.data.error && response.data.error === 'verification_required') {
                        return response
                    }

                    // Set the error
                    context.commit('setSoftError', {
                        key: response.data.error,
                        message: response.data.error_description
                    })

                    // If the login somehow is not valid we delete the token
                    localStorage.removeItem('token')
                    localStorage.removeItem('refreshtoken')
                    return response
                } else {
                    // Set a generic error message
                    context.commit('setSoftError', {
                        key: 'loginUnkownError',
                        message: 'Der skete en ukent fejl'
                    })
                    // If the login somehow is not valid we delete the token
                    localStorage.removeItem('token')
                    localStorage.removeItem('refreshtoken')
                }

                return response
            })
        },
        fetchMyUser: context => {
            const myUserCall = apiMyUser(false)
            const authCall = Promise.resolve({
                status: 100,
                responseBody: {
                    isAuthenticated: false
                }
            })

            return Promise.all([myUserCall, authCall])
                .then(responses => {
                    const myUserResponse = responses[0]
                    const authResponse = responses[1]

                    const myUser = myUserResponse.responseBody
                    const auth = authResponse.responseBody

                    if (myUserResponse.status === 401) {
                        throw new Error('Unauthenticated')
                    }

                    if (myUserResponse.status === 403) {
                        throw new Error('Forbidden')
                    }

                    const loginMethod = auth.isAuthenticated ? 'auth' : 'token'

                    context.commit('setMyUser', {
                        loginMethod,
                        user: myUser
                    })
                })
        },
    }
}