import {createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import('../views/Index.vue'),
        children: [
            {
                path: '',
                name: 'Login',
                component: () => import('../views/LoginPage.vue'),
                meta: {
                    title: 'Login'
                }
            },
            {
                path: '/messagelist',
                name: 'MessageList',
                component: () => import('../views/dashboard/MessageList.vue'),
                meta: {
                    title: 'MessageList'   
                }
            },
            {
                path: '/createnewnotification',
                name: 'CreateNewNotification',
                component: () => import('../views/dashboard/CreateNewNotification.vue'),
                meta: {
                    title: 'CreateNewNotification'
                },
                props: true

            },
            {
                path: '/editNotification',
                name: 'EditNotification',
                component: () => import('../views/dashboard/CreateNewNotification.vue'),
                meta: {
                    title: 'EditNotification'
                }
            },
        ]
    }]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    mode: 'history'
})

router.beforeEach(to => {
    document.title = to.meta.title || 'Optidata'
})

export default router