export default {
    state: {
        // Holds soft errors from either the api or custom errors from this app
        softErrors: []
    },
    mutations: {
        setSoftError: (state, payload) => {
            let existing = state.softErrors.findIndex(x => x.key === payload.key)
            if (existing === -1) {
                state.softErrors.push({
                    key: payload.key.replace(/\s+/g, '').toLowerCase(),
                    message: payload.message
                })
            }
        },
        removeSoftError: (state, payload) => {
            let index = state.softErrors.findIndex(x => x.key === payload)
            if (index >= 0) {
                state.softErrors.splice(index, 1)
            }
        },
        clearSoftErrors: state => {
            state.softErrors = []
        }
    },
    getters: {
        getSoftErrors: state => {
            return state.softErrors
        }
    },
    actions: {
    }
}
