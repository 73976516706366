import {GetAllActiveNotifications} from '@/api/Notifications/GetAllActiveNotifications'
import {SendNotification} from '@/api/Notifications/SendNotification'
import {GetNotificationDetails} from '@/api/Notifications/GetNotificationDetails'

import {GetRecycleApiKeys} from '@/api/Notifications/GetRecycleApiKeys'

export default{
    getters: {
    },
    actions: {
        getAllActiveNotifications(){
            return GetAllActiveNotifications().then(response => {
                return response.data
            })
        },
        getRecycleAPiKeys(){
            return GetRecycleApiKeys().then(response => {
                return response.data.RecycleApiKeys
            })
        },
        sendNotification(context, payload){
            return SendNotification(payload.title, payload.message, payload.utcDateFrom, payload.utcDateTo, payload.type, payload.recycleApiKeys, payload.identifier)
                .then(response => {
                    return response.data
                })
        },
        getNotificationDetails(context, payload){
            return GetNotificationDetails(payload.identifier).then(response => {
                return response.data
            })
        }
    },
}
