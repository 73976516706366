import axios from 'axios'
import router from '../router'

export const callApi = function(method, endpoint, body, overrideContentType, overrideAccept, overriddeBody){    
    // Create the request
    const request = {
        method,
        url: process.env.VUE_APP_API_URL + endpoint,
        headers: {
            'Content-Type': overrideContentType || 'application/json', 
            'Accept': overrideAccept || 'application/json'
        },
        cache: 'no-cache',
        credentials: 'include'
    }

    // If a login token is present. Put it as a header
    if (localStorage.getItem('token')) {
        // If we have an authorization token. put it in headers
        request.headers['Authorization'] = `bearer ${localStorage.getItem('token')}`
    }

    // If a body is present - put it in the request
    if (body) {
        request.data = JSON.stringify(body)
    }

    if(overriddeBody) {
        request.data = overriddeBody
    }
    return axios(request)
        .then(response => {         
            return response
        })
        .catch(error => {
            if(error.response && error.response.status === 401){
                router.push({name: 'Login'})
            }
            return error.name           
        })
}