import { callApi } from '@/api/api-core'

export const login = function(username, password) {
    const requestData = {
        grant_type: 'password',
        username,
        password
    }

    // eslint-disable-next-line camelcase
    let overrideBody = `grant_type=${requestData.grant_type}&username=${encodeURIComponent(requestData.username)}&password=${encodeURIComponent(requestData.password)}`
    return callApi('POST', 'Token', null, 'application/x-www-form-urlencoded', '*', overrideBody)
}

export const twoFactorLogin = function(userName, twoFactorType) {
    const requestData = {
        UserName: userName,
        TwofactorType: twoFactorType
    }

    return callApi('POST', 'api/twofactor/authorize', requestData, false, null, null, null, false, true)
}

export const auth = function() {
    return callApi('GET', 'auth/userinfo')
}
