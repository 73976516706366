import { createApp } from 'vue'
import App from './App.vue'


import router from './router'
import vuex from 'vuex'
import store from './store'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'



const vuetify = createVuetify({
    components,
    directives,
})

const app =createApp(App)
    .use(router)
    .use(vuetify)
    .use(store)
    .use(vuex)
    // .use(i18n)

app.component('VueDatePicker', VueDatePicker)

app.mount('#app')